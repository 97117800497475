<template>
  <div>
    <a @click="fnShowModal()" class="p-2"
      ><i class="far fa-edit text-info"></i
    ></a>

    <b-modal ref="modal" hide-footer title="Product - update">
      <div class="d-block">
        <form v-on:submit.prevent="fnUpdate">
          <div class="form-group">
            <select class="form-control" v-model="product_category_id">
              <option
                :value="item.id"
                v-for="(item, i) in getProductCategoryList"
                :key="i"
              >
                {{ item.name }}
              </option>
            </select>
            <label class="floatingLabel vueBootSelect" for="product_category"
              >Product Category</label
            >
            <div class="invalid-feedback" v-if="errors.product_category_id">
              {{ errors.product_category_id[0] }}
            </div>
          </div>

          <div class="form-group">
            <input
              class="form-control"
              type="text"
              autocomplete="off_name"
              placeholder=" "
              disabled
              v-model="name"
            />
            <label class="floatingLabel" for="name">Name</label>
          </div>
          <div class="form-group">
            <input
              class="form-control"
              type="number"
              step="any"
              autocomplete="off_unit_price"
              placeholder=" "
              v-model="unit_price"
            />
            <label class="floatingLabel" for="name">Unit price</label>
            <div class="invalid-feedback" v-if="errors.unit_price">
              {{ errors.unit_price[0] }}
            </div>
          </div>
          <div class="form-group">
            <input
              class="form-control"
              type="text"
              autocomplete="off_fa_icon"
              placeholder=" "
              v-model="fa_icon"
            />
            <label class="floatingLabel" for="name">Fa Icon</label>
            <div class="invalid-feedback" v-if="errors.fa_icon">
              {{ errors.fa_icon[0] }}
            </div>
          </div>
          <b-form-checkbox
            id="checkbox-1"
            v-model="status"
            name="checkbox-1"
            value="1"
            unchecked-value="0"
          >
            Status
          </b-form-checkbox>

          <div class="text-right mt1500">
            <button
              type="button"
              class="btn btn-secondary btn-sm mr-2"
              @click="hideModal"
            >
              Cancel
            </button>
            <button type="submit" class="btn btn-info btn-sm">Save</button>
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as messageService from "../../services/message.service";
import * as productService from "../../services/product.service";
export default {
  name: "ModalProductEdit",
  props: ["propsData"],
  components: {},
  data() {
    return {
      errors: {},
      id: "",
      name: "",
      product_category_id: "",
      unit_price: 0,
      fa_icon: "",
      status: "",
    };
  },
  computed: {
    ...mapGetters(["getProductCategoryList"]),
  },
  methods: {
    ...mapActions(["fetchProductList", "fetchProductCategoryList"]),
    fnShowModal() {
      this.fnGetValue(this.propsData);
      this.showModal();
    },
    hideModal() {
      this.$refs.modal.hide();
    },
    showModal() {
      this.$refs.modal.show();
    },

    fnGetValue(props) {
      this.id = props.rowData.id;
      this.name = props.rowData.name;
      this.product_category_id = props.rowData.product_category_id;
      this.unit_price = props.rowData.unit_price;
      this.fa_icon = props.rowData.fa_icon;
      this.status = props.rowData.status;
    },

    fnUpdate: async function () {
      const obj = {
        product_category_id: this.product_category_id,
        unit_price: this.unit_price,
        fa_icon: this.fa_icon,
        status: this.status,
      };

      try {
        this.errors = {};

        await productService.update(this.id, obj);

        this.fetchProductList();
        messageService.fnSweetAlertSuccessToast(
          "Product",
          "Updated successfully "
        );
        this.hideModal();

        this.id = "";
        this.name = "";
        this.product_category_id = "";
        this.unit_price = "";
        this.fa_icon = "";
        this.status = "";
      } catch (error) {
        messageService.fnSweetAlertErrorToast(
          "Product - update",
          error.response.data.message
        );
      }
    },
  },
};
</script>

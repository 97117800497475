<template>
  <div>
    <button
      class="btn btn-info btn-sm"
      data-toggle="tooltip"
      data-placement="top"
      title="New"
      @click="fnShowModal()"
    >
      New
    </button>

    <b-modal ref="modal" hide-footer title="Product - new">
      <div class="d-block">
        <form v-on:submit.prevent="fnCreateNew">
          <div class="form-group">
            <select class="form-control" v-model="product_category_id">
              <option
                :value="item.id"
                v-for="(item, i) in getProductCategoryList"
                :key="i"
              >
                {{ item.name }}
              </option>
            </select>
            <label class="floatingLabel vueBootSelect" for="product_category"
              >Product Category</label
            >
            <div class="invalid-feedback" v-if="errors.product_category_id">
              {{ errors.product_category_id[0] }}
            </div>
          </div>

          <div class="form-group">
            <input
              class="form-control"
              type="text"
              autocomplete="off_name"
              placeholder=" "
              v-model="name"
            />
            <label class="floatingLabel" for="name">Name</label>
            <div class="invalid-feedback" v-if="errors.name">
              {{ errors.name[0] }}
            </div>
          </div>
          <div class="form-group">
            <input
              class="form-control"
              type="number"
              step="any"
              autocomplete="off_unit_price"
              placeholder=" "
              v-model="unit_price"
            />
            <label class="floatingLabel" for="name">Unit price</label>
            <div class="invalid-feedback" v-if="errors.unit_price">
              {{ errors.unit_price[0] }}
            </div>
          </div>
          <div class="form-group">
            <input
              class="form-control"
              type="text"
              autocomplete="off_fa_icon"
              placeholder=" "
              v-model="fa_icon"
            />
            <label class="floatingLabel" for="name">Fa Icon</label>
            <div class="invalid-feedback" v-if="errors.fa_icon">
              {{ errors.fa_icon[0] }}
            </div>
          </div>

          <div class="text-right mt1500">
            <button
              type="button"
              class="btn btn-secondary btn-sm mr-2"
              @click="hideModal"
            >
              Cancel
            </button>
            <button type="submit" class="btn btn-info btn-sm">Save</button>
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as messageService from "../../services/message.service";
import * as productService from "../../services/product.service";
export default {
  name: "ModalProductNew",
  components: {},
  data() {
    return {
      errors: {},
      name: "",
      product_category_id: "",
      unit_price: 0,
      fa_icon: "",
    };
  },
  computed: {
    ...mapGetters(["getProductCategoryList"]),
  },
  methods: {
    ...mapActions(["fetchProductList", "fetchProductCategoryList"]),
    fnShowModal() {
      this.showModal();
    },
    hideModal() {
      this.$refs.modal.hide();
    },
    showModal() {
      this.$refs.modal.show();
    },

    fnCreateNew: async function () {
      let obj = {
        name: this.name,
        product_category_id: this.product_category_id,
        unit_price: this.unit_price,
        fa_icon: this.fa_icon,
      };
      try {
        this.errors = {};

        const response = await productService.add(obj);
        this.hideModal();
        this.fetchProductList();

        messageService.fnSweetAlertSuccessToast(
          "Product - new",
          "added successfully"
        );

        this.name = "";
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Product - new",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Product - new",
              "Something wrong"
            );
            break;
        }
      }
    },
  },
  created() {
    this.fetchProductCategoryList();
  },
};
</script>
